import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Menu, LogOut } from 'lucide-react'
import { APP_NAME } from '../../constants/global'
import { getName, clearAuthData } from '../../utils/auth'
import { useSidebar } from '../../contexts/SidebarContext'

export default function Header() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [userInitials, setUserInitials] = useState('')
    const navigate = useNavigate()
    const { toggleSidebar } = useSidebar()

    useEffect(() => {
        const name = getName()
        if (name) {
            const initials = name.split(' ').map(n => n[0]).join('').toUpperCase()
            setUserInitials(initials)
        }
    }, [])

    const handleLogout = () => {
        clearAuthData()
        navigate('/login')
    }

    return (
        <header className="bg-primary text-white h-16 flex items-center px-4">
            <button
                onClick={toggleSidebar}
                className="text-white p-2 rounded-md hover:bg-white/10"
                aria-label="Toggle navigation menu"
            >
                <Menu className="h-6 w-6" />
            </button>
            <div className="flex-1 text-center">
                <Link to="/" className="text-3xl font-bold tracking-wide">{APP_NAME}</Link>
            </div>
            <div className="relative">
                <button
                    className="w-10 h-10 rounded-full bg-secondary text-white flex items-center justify-center text-lg font-semibold"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    aria-haspopup="true"
                    aria-expanded={isDropdownOpen}
                >
                    {userInitials}
                </button>
                {isDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white">
                        <div className="py-1" role="menu" aria-orientation="vertical">
                            <button
                                onClick={handleLogout}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                role="menuitem"
                            >
                                <LogOut className="inline-block mr-2 h-5 w-5" />
                                Logout
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </header>
    )
}