import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import AuthenticatedLayout from './components/layout/AuthenticatedLayout'

const Dashboard = lazy(() => import('./pages/Dashboard'))
const Login = lazy(() => import('./pages/Login'))
const Policies = lazy(() => import('./pages/Policies'))
const SupportTickets = lazy(() => import('./pages/SupportTickets'))
const Integrations = lazy(() => import('./pages/Integrations'))
const IntegrationInitiate = lazy(() => import('./pages/IntegrationInitiate'))
const IntegrationConfirm = lazy(() => import('./pages/IntegrationConfirm'))
const RecommendationApproval = lazy(() => import('./pages/RecommendationApproval'))


const NotFound = lazy(() => import('./pages/NotFound'))

export const routes = [
    {
        path: '/login',
        element: <Login />
    },
    {
        element: <AuthenticatedLayout />,
        children: [
            {
                path: '/',
                element: <Navigate to="/dashboard" replace />
            },
            {
                path: '/dashboard',
                element: <Dashboard />
            },
            {
                path: '/policies',
                element: <Policies />
            },
            {
                path: '/support-tickets',
                element: <SupportTickets />
            },
            {
                path: '/integrations',
                element: <Integrations />
            },
            {
                path: '/integrations/:appName/initiate',
                element: <IntegrationInitiate />
            },
            {
                path: '/integrations/:appName/confirm',
                element: <IntegrationConfirm />
            },
            {
                path: '/recommendations/approval/:recoId',
                element: <RecommendationApproval />
            },
        ]
    },
    {
        path: '*',
        element: <NotFound />
    },
]