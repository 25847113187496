import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import Sidebar from './Sidebar'
import { SidebarProvider } from '../../contexts/SidebarContext'

export default function AuthenticatedLayout() {
    return (
        <SidebarProvider>
            <div className="flex flex-col h-screen">
                <Header />
                <div className="flex flex-1 overflow-hidden">
                    <Sidebar />
                    <main className="flex-1 overflow-y-auto bg-gray-100 p-4">
                        <Outlet />
                    </main>
                </div>
            </div>
        </SidebarProvider>
    )
}
