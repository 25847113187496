import React from 'react'

interface SpinnerProps {
  size?: 'small' | 'medium' | 'large'
  color?: 'primary' | 'secondary' | 'success' | 'danger'
}

export default function Spinner({ size = 'medium', color = 'primary' }: SpinnerProps) {
  const sizeClasses = {
    small: 'w-4 h-4 border-2',
    medium: 'w-8 h-8 border-3',
    large: 'w-12 h-12 border-4'
  }

  const colorClasses = {
    primary: 'border-primary',
    secondary: 'border-secondary',
    success: 'border-success',
    danger: 'border-danger'
  }

  return (
      <div className="flex justify-center items-center">
        <div
            className={`
          animate-spin 
          rounded-full 
          ${sizeClasses[size]} 
          ${colorClasses[color]}
          border-t-transparent
        `}
        ></div>
      </div>
  )
}