import React, { Suspense, useEffect } from 'react'
import { BrowserRouter as Router, useRoutes, useNavigate, useLocation } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from './contexts/ThemeContext'
import ErrorModal from './components/common/ErrorModal'
import Spinner from './components/common/Spinner'
import { routes } from './routes'
import { getToken } from './utils/auth'
import { setNavigationFunction } from './utils/navigation';

const queryClient = new QueryClient()

const AppRoutes = () => {
  const element = useRoutes(routes)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const token = getToken()
    if (token && location.pathname === '/login') {
      navigate('/')
    } else if (!token && location.pathname !== '/login') {
      navigate('/login')
    }
  }, [navigate, location])

  useEffect(() => {
    setNavigationFunction(navigate);
  }, [navigate]);

  return element
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <Router>
          <div className="flex flex-col min-h-screen font-sans bg-white">
            <main className="flex-grow bg-gray-50">
              <Suspense fallback={
                <div className="flex justify-center items-center h-full">
                  <Spinner size="large" color="primary" />
                </div>
              }>
                <AppRoutes />
              </Suspense>
            </main>
          </div>
          <ErrorModal />
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App