import {useNavigate} from 'react-router-dom';

interface Account {
    role: string;
    company: string;
    company_name: string;
}

interface PartnerAccount {
    role: string;
    partner: string;
    partner_name: string;
}

interface AccountInfo {
    is_partner: boolean;
    accounts: Account[];
    partner_accounts: PartnerAccount[];
}

interface LoginResponse {
    email: string;
    account_info: AccountInfo;
    first_name: string;
    last_name: string;
    access_token: string;
}

export function processLoginResponse(response: LoginResponse): void {
    const { email, account_info, first_name, last_name, access_token } = response;

    // Store the access token
    localStorage.setItem('token', access_token);

    // Store name
    const name = `${first_name} ${last_name}`;
    localStorage.setItem('name', name);

    // Store role (first role from the list)
    const role = account_info.accounts[0]?.role || '';
    if (role && role.trim() !== '') {
        localStorage.setItem('role', role);
    }

    // Store company (first company from the list)
    const company = account_info.accounts[0]?.company || '';
    if (company && company.trim() !== '') {
        localStorage.setItem('company', company);
    }

    // Store partner (first partner from the list)
    const partner = account_info.partner_accounts[0]?.partner || '';
    if (partner && partner.trim() !== '') {
        localStorage.setItem('partner', partner);
    }

    // Store partner status
    localStorage.setItem('isPartner', account_info.is_partner.toString());

    // Store all accounts
    localStorage.setItem('accounts', JSON.stringify(account_info.accounts));

    // Store partner accounts
    localStorage.setItem('partnerAccounts', JSON.stringify(account_info.partner_accounts));

    // Store user information
    const userInfo = {
        email,
        name,
        role,
        company,
        accounts: account_info.accounts,
        isPartner: account_info.is_partner,
        partnerAccounts: account_info.partner_accounts
    };
    localStorage.setItem('user', JSON.stringify(userInfo));
}

export function clearAuthData(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('role');
    localStorage.removeItem('company');
    localStorage.removeItem('partner');
    localStorage.removeItem('isPartner');
    localStorage.removeItem('accounts');
    localStorage.removeItem('partnerAccounts');
    localStorage.removeItem('user');
}

export function isAuthenticated(): boolean {
    return !!localStorage.getItem('token');
}

export function getUser(): Record<string, any> | null {
    const userString = localStorage.getItem('user');
    return userString ? JSON.parse(userString) : null;
}

export function getToken(): string | null {
    return localStorage.getItem('token');
}

export function getName(): string | null {
    return localStorage.getItem('name');
}

export function getRole(): string | null {
    return localStorage.getItem('role');
}

export function getCompany(): string | null {
    return localStorage.getItem('company');
}

export function getPartner(): string | null {
    return localStorage.getItem('partner');
}

export function getAccounts(): Account[] | null {
    const accountsString = localStorage.getItem('accounts');
    return accountsString ? JSON.parse(accountsString) : null;
}

export function getPartnerAccounts(): any[] | null {
    const partnerAccountsString = localStorage.getItem('partnerAccounts');
    return partnerAccountsString ? JSON.parse(partnerAccountsString) : null;
}

export const useLogout = () => {
    const navigate = useNavigate();

    return () => {
        // Clear local storage
        clearAuthData();
        // Redirect to the base URL
        navigate('/login');
    };
};
