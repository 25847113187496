import React, { ReactNode } from 'react'
import { X } from 'lucide-react'

interface DialogProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    children: ReactNode;
}

export const Dialog: React.FC<DialogProps> = ({ open, onOpenChange, children }) => {
    if (!open) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg max-w-md w-full border-2 border-secondary shadow-secondary">
                {React.Children.map(children, child =>
                    React.isValidElement(child)
                        ? React.cloneElement(child as React.ReactElement<any>, { onOpenChange })
                        : child
                )}
            </div>
        </div>
    );
};

interface DialogContentProps {
    children: ReactNode;
    className?: string;
    onOpenChange?: (open: boolean) => void;
}

export const DialogContent: React.FC<DialogContentProps> = ({ children, className, onOpenChange }) => (
    <div className={`p-6 text-center ${className || ''}`}>
        {children}
        <button
            className="mt-6 inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-primary text-primary-foreground hover:bg-primary/90 h-10 py-2 px-4"
            onClick={() => onOpenChange && onOpenChange(false)}
        >
            <X className="mr-2 h-4 w-4" />
            Close
        </button>
    </div>
);

export const DialogHeader: React.FC<{ children: ReactNode; className?: string }> = ({ children, className }) => (
    <div className={`flex flex-col items-center justify-center mb-4 ${className || ''}`}>
        {children}
    </div>
);

export const DialogTitle: React.FC<{ children: ReactNode; className?: string }> = ({ children, className }) => (
    <h2 className={`text-lg font-semibold leading-none tracking-tight ${className || ''}`}>
        {children}
    </h2>
);