let navigate: (path: string) => void;

export const setNavigationFunction = (navigateFunction: (path: string) => void) => {
    navigate = navigateFunction;
};

export const navigateToLogin = () => {
    if (navigate) {
        navigate('/login');
    } else {
        console.error('Navigation function not set');
        // Fallback to window.location if navigate is not available
        window.location.href = '/login';
    }
};
