import React from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./Dialog"
import { useErrorStore } from '../../services/api'

const ErrorModal: React.FC = () => {
    const { isErrorModalOpen, setErrorModalOpen, errorTitle, errorMessage } = useErrorStore()

    return (
        <Dialog open={isErrorModalOpen} onOpenChange={setErrorModalOpen}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        <span className="text-primary text-xl font-bold">{errorTitle}</span>
                    </DialogTitle>
                </DialogHeader>
                <p className="text-black mt-4">{errorMessage}</p>
            </DialogContent>
        </Dialog>
    )
}

export default ErrorModal